import { Component, Vue } from 'vue-property-decorator'
import { LoginUser } from '@/generated/LoginUser'
import {
  ChangePasswordModel,
  CreateUserModel,
  LoginUserModel,
  Pagination,
  UpdateUserModel
} from '@/generated/globalTypes'
import gql from 'graphql-tag'
import { ListUsers, ListUsers_ListUsers } from '@/generated/ListUsers'
import { UpdateUser_UpdateUser } from '@/generated/UpdateUser'
import { GetUser, GetUser_GetUser } from '@/generated/GetUser'
import { CreateUser_CreateUser } from '@/generated/CreateUser'
import { ApolloQueryResult } from 'apollo-client'
import { FetchResult } from 'apollo-link'
import { ChangePassword, ChangePassword_ChangePassword } from '@/generated/ChangePassword'
import {UpdateFirebaseToken} from "@/generated/UpdateFirebaseToken";
import {DeleteFirebaseToken} from "@/generated/DeleteFirebaseToken";

@Component
export default class UserService1 extends Vue {
  private loginUser = gql`
    query LoginUser($user: LoginUserModel!) {
      LoginUser(a0: $user)
    }
  `
  private listUsersQuery = gql`
    query ListUsers($pagination: Pagination!) {
      ListUsers(a0: $pagination) {
        items {
          id
          email
          created
        }
        total
      }
    }
  `

  private getUserQuery = gql`
    query GetUser($uuid: Int!) {
      GetUser(a0: $uuid) {
        id
        email
        authorities
        locked
      }
    }
  `

  private updateUserQuery = gql`
    mutation UpdateUser($updateUser: UpdateUserModel!) {
      UpdateUser(a0: $updateUser) {
        id
        email
        authorities
      }
    }
  `

  private createUserQuery = gql`
    mutation CreateUser($createUser: CreateUserModel!) {
      CreateUser(a0: $createUser) {
        id
        email
        authorities
      }
    }
  `

  private changePasswordQuery = gql`
    mutation ChangePassword($changePassword: ChangePasswordModel!) {
      ChangePassword(a0: $changePassword) {
        id
        email
        authorities
      }
    }
  `

  private updateFirebaseTokenQuery = gql`
    mutation UpdateFirebaseToken($token: String!) {
      UpdateFirebaseToken(a0: $token)
    }
  `

  private deleteFirebaseTokenQuery = gql`
    mutation DeleteFirebaseToken($token: String!) {
      DeleteFirebaseToken(a0: $token)
    }
  `

  authenticateUser(email: string, password: string): Promise<LoginUser> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.loginUser,
          variables: {
            user: { email: email, password: password } as LoginUserModel
          }
        })
        .then((result: ApolloQueryResult<LoginUser>) => {
          resolve(result.data)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  listUsers(entityID: number | null = null, pagination: Pagination): Promise<ListUsers_ListUsers> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listUsersQuery,
          variables: {
            pagination: pagination
          }
        })
        .then((result: ApolloQueryResult<ListUsers>) => {
          resolve(result.data.ListUsers)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  getUser(id: string): Promise<GetUser_GetUser> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.getUserQuery,
          variables: {
            uuid: id
          }
        })
        .then((result: ApolloQueryResult<GetUser>) => {
          resolve(result.data.GetUser)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  updateUser(updateUser: UpdateUserModel): Promise<UpdateUser_UpdateUser> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .mutate({
          mutation: this.updateUserQuery,
          variables: {
            updateUser: updateUser
          }
        })
        .then(result => {
          resolve(result.data.UpdateUser)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  createUser(createUser: CreateUserModel): Promise<CreateUser_CreateUser> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .mutate({
          mutation: this.createUserQuery,
          variables: {
            createUser: createUser
          }
        })
        .then(result => {
          resolve(result.data.CreateUser)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  changePassword(changePassword: ChangePasswordModel): Promise<ChangePassword_ChangePassword> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .mutate({
          mutation: this.changePasswordQuery,
          variables: {
            changePassword: changePassword
          }
        })
        .then((result: FetchResult<ChangePassword>) => {
          resolve(result.data?.ChangePassword)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  updateFirebaseToken(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .mutate({
            mutation: this.updateFirebaseTokenQuery,
            variables: {
              token: token
            }
          })
          .then((result: FetchResult<UpdateFirebaseToken>) => {
            resolve(result.data?.UpdateFirebaseToken)
          })
          .catch(error => {
            reject(error)
          })
    })
  }

  deleteFirebaseToken(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .mutate({
            mutation: this.deleteFirebaseTokenQuery,
            variables: {
              token: token
            }
          })
          .then((result: FetchResult<DeleteFirebaseToken>) => {
            resolve(result.data?.DeleteFirebaseToken)
          })
          .catch(error => {
            reject(error)
          })
    })
  }
}
