











































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import InputMixin from '@/general/mixins/InputMixin'

class Validation {
  message: string
  regex: RegExp
  valid = false

  constructor(message: string, regex: string) {
    this.message = message
    this.regex = new RegExp(regex)
  }
}

@Component
export default class BaseTextField extends Mixins(InputMixin) {
  menu = false
  allValid = false
  @Prop({ default: 'password' }) vid: string
  @Prop({ default: 'user.fields.password' }) label: string

  validations = [
    new Validation('user.validation.length', '.{8,}'),
    new Validation('user.validation.lowercase', '(?=.*[a-z])'),
    new Validation('user.validation.uppercase', '(?=.*[A-Z])'),
    new Validation('user.validation.number', '(?=.*[0-9])'),
    new Validation('user.validation.special', '(?=.*[!@#$%^&*_=+-])')
  ]

  get validatorSpecific() {
    return {
      required: true,
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/
    }
  }

  @Watch('value')
  checkValidations(password: string) {
    let allValid = true

    this.validations.forEach((element: Validation) => {
      element.valid = element.regex.test(password)

      if (!element.valid) allValid = false
    })

    this.allValid = allValid
  }
}
