



































import { Component, Mixins } from 'vue-property-decorator'
import FormMixin from '@/general/mixins/FormMixin'
import SaveButton from '@/general/components/common/SaveButton.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import OptionMenuButton from '@/general/components/common/OptionMenuButton.vue'
import PasswordField from '@/modules/users/components/PasswordField.vue'
import { ValidationObserver } from 'vee-validate'
import BaseAutocomplete from '@/general/components/general/BaseAutocomplete.vue'
import BaseForm from '@/general/components/general/BaseForm.vue'
import BaseCheckBox from '@/general/components/general/BaseCheckBox.vue'
import { UpdateUserVariables } from '@/generated/UpdateUser'
import UserService1 from '@/modules/users/UserService1'
import { GetUser_GetUser } from '@/generated/GetUser'
import { CreateUserModel, UpdateUserModel } from '@/generated/globalTypes'

enum Roles {
  ROLE_ERP = 'ERP',
  USER = 'USER',
  TRANSPORT = 'TRANSPORT',
  SHIPMENT = 'SHIPMENT',
  COMPANY = 'COMPANY'
}

@Component({
  components: {
    BaseCheckBox,
    BaseForm,
    BaseAutocomplete,
    BaseTextField,
    InfoCard,
    SaveButton,
    OptionMenuButton,
    PasswordField,
    ValidationObserver
  }
})
export default class CreateEdit extends Mixins(FormMixin, UserService1) {
  user: UpdateUserModel = {
    id: -1,
    authorities: [],
    email: '',
    locked: false
  }

  password: string = ''
  repeatPassword: string = ''
  id: string = ''

  get items() {
    return [
      { icon: 'lock', text: this.$t('actions.saveObj', [this.$t('user.fields.password')]), translate: false },
      { icon: 'save', text: this.$t('actions.saveObj', [this.$t('user.single')]), translate: false }
    ]
  }

  validator(): any {
    return false
  }

  created() {
    if (this.$route.params.id || Number(this.$route.params.id) === 0) {
      this.id = this.$route.params.id
      this.get().then(response => {
        console.log(response)
        this.user = {
          authorities: response.authorities,
          id: response.id,
          email: response.email,
          locked: response.locked
        }
      })
    }
  }

  get(): Promise<GetUser_GetUser> {
    return this.getUser(this.$route.params.id)
  }

  handleOptionMenu(buttonPressed: string) {
    switch (buttonPressed) {
      case 'save':
        this.submitForm()
        break
      case 'lock':
        ;(this.$refs.password as any).validate().then((result: any) => {
          if (result) {
            this.submitPassword()
          }
        })
        break
      default:
        break
    }
  }

  submit() {
    if (this.$route.params.id || Number(this.$route.params.id) === 0) {
      this.updateUser(this.user).then(response => {
        this.$store.dispatch('snackbar/saved')
        this.$router.push({ name: 'user-overview' })
      })
    } else {
      this.createUser({
        authorities: this.user.authorities,
        email: this.user.email,
        password: this.password
      } as CreateUserModel)
        .then(result => {
          this.$store.dispatch('snackbar/saved')
          this.$router.push({ name: 'user-overview' })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/error', error)
        })
    }
  }

  submitPassword() {
    if (this.password != this.repeatPassword) {
      this.$store.dispatch('snackbar/error')
    } else {
      this.changePassword({
        id: this.user.id,
        password: this.password
      }).then(response => {
        this.$store.dispatch('snackbar/saved')
        this.$router.push({ name: 'user-overview' })
      })
    }
  }
}
